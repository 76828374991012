import { FC, PropsWithChildren, useCallback, useEffect } from 'react';
import {
	useAppIsViewLocked,
	useAppMobile,
} from '@common/selectors/app.selectors';
import {
	StyledMainDashboard,
	StyledMainDashboardProps,
	StyledMainDashboardV2,
} from '../../modules/style/components/MainDashboard.styled';
import { TransactionsHistory } from '../transactionsHistory/TransactionsHistory';
import { setLockedView } from '../../store/app/app.actions';
import { useAppDispatch } from '../../../hooks/store/useAppDispatch';

export interface MainLayoutProps extends StyledMainDashboardProps {
	lockView?: boolean;
	v2?: boolean;
	showTransactions?: boolean;
	disableTransactions?: boolean;
}

export const MainLayout: FC<PropsWithChildren<MainLayoutProps>> = ({
	children,
	lockView,
	v2,
	showTransactions,
	disableTransactions,
	...props
}) => {
	const isMobile = useAppMobile();
	const locked = useAppIsViewLocked();
	const dispatch = useAppDispatch();

	const Dashboard = v2 ? StyledMainDashboardV2 : StyledMainDashboard;

	const setLockedView_ = useCallback(
		(isLockedView: boolean) => {
			if (!lockView) {
				return;
			}
			if (isLockedView !== locked) {
				dispatch(setLockedView('mainLayout', isLockedView));
			}
		},
		[dispatch, lockView, locked],
	);

	useEffect(() => {
		setLockedView_(true);
		return () => {
			setLockedView_(false);
		};
	}, [setLockedView_]);

	return (
		<Dashboard {...props}>
			{children}

			{(!isMobile || showTransactions) && !disableTransactions && (
				<TransactionsHistory />
			)}
		</Dashboard>
	);
};
