import { FC } from 'react';

import { LOADING_IDS } from '@common/constants/fetch/loading.ids';
import { useOnMount } from '@common/hooks/useOnMount';
import { getTransactionsThunk } from '@legacyApp/client/store/transactions/thunks/getTransactions.thunk';
import {
	useTransactions,
	useTransactionsMeta,
} from '@legacyApp/client/store/transactions/transactions.selectors';
import { useAppDispatch } from '@legacyApp/hooks/store/useAppDispatch';
import { Bet } from '@modules/games/Game/store/game/@types/PlaceBetResponse';
import { columnsService } from '@modules/table/columnsService';
import { TABLE_ID } from '@modules/transactions/constants/TableId';
import transactionService from '@modules/transactions/transactionsService';

import { NoResultsElement } from '@common/components/layout/Table/components/noResultsElement';
import { TableLoadingRow } from '@common/components/layout/Table/components/tableLoadingRow';
import { TableRow } from '@common/components/layout/Table/components/tableRow';
import { TableRowContent } from '@common/components/layout/Table/components/tableRowContent';
import {
	StyledTableNoScroll,
	StyledTableRow,
	StyledTBody,
	StyledTHead,
} from '@common/components/layout/Table/components/Table.styled';

const COLUMNS = columnsService.getColumns(TABLE_ID.myBets);

const ROW_COMPARE_KEYS = transactionService.getRowCompareKeys(TABLE_ID.myBets);

const MyBetsTable: FC<{
	limit?: number;
}> = ({ limit }) => {
	const dispatch = useAppDispatch();

	const meta = useTransactionsMeta(TABLE_ID.myBets);

	const data = useTransactions<Bet>(TABLE_ID.myBets);

	useOnMount(() => {
		if (!meta?.current_page || (meta?.current_page && meta?.current_page > 1)) {
			dispatch(
				getTransactionsThunk({
					id: TABLE_ID.myBets,
					page: 1,
					disable: false,
					refresh: true,
				}),
			);
		}
	});

	const dataSliced = data.slice(0, limit || data?.length);

	return (
		<StyledTableNoScroll
			as="div"
			name={`table-${TABLE_ID.myBets}`}
			$noResults={!data.length}
			className={`table-${TABLE_ID.myBets}`}
		>
			<>
				<StyledTHead as="div">
					<StyledTableRow as="div">
						<TableRowContent columns={COLUMNS} />
					</StyledTableRow>
				</StyledTHead>

				<StyledTBody $length={dataSliced?.length || 1} as="div">
					<>
						{!!dataSliced.length &&
							dataSliced.map((el, index) => (
								<TableRow
									rowClass="initial-blink"
									key={`${TABLE_ID.myBets}_${el.hash}`}
									el={el}
									index={index}
									columns={COLUMNS}
									compareKeys={ROW_COMPARE_KEYS}
								/>
							))}

						<TableLoadingRow
							id={LOADING_IDS.GET_USER_BETS}
							isData={!!dataSliced.length && dataSliced.length > 4}
							message={
								!dataSliced.length && (
									<NoResultsElement
										noResultsText={'Your bets history is empty'}
									/>
								)
							}
						/>
					</>
				</StyledTBody>
			</>
		</StyledTableNoScroll>
	);
};

export { MyBetsTable };
